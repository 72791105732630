// import { BASE_URL } from "../../configuration/apiconfig";
// import UserStore from "../../store/UserStore";
import API from "../../api/api";

export default class PaymentView {
  API_KEY = localStorage.getItem("apiKey");

  paymentStatusCheck = async (paylod) => {
    console.log(paylod);
    return await API.API.post("cart/payment_success", paylod)
      .then((res) => {
        console.log('paymentresponse :: ', res)
        if (res.status === 204) {
          return {
            status: true,
          };
        } else {
          return res;
        }
      })
      .catch((error) => console.log(error));
  };

  subscriptionPaymentStatusCheck = async (paylod) => {
    console.log(paylod);
    return await API.API.post("cart/subscription_payment_success", paylod)
      .then((res) => {
        console.log('paymentresponse :: ', res)
        if (res.status === 204) {
          return {
            status: true,
          };
        } else {
          return res;
        }
      })
      .catch((error) => console.log(error));
  };



  // paymentStatusCheck = async (paylod) => {
  //   return await API.API.get("cart/payment_success",paylod)
  //     .then((res) => {
  //       if (res.status == 200) {
  //         return res.data;
  //       } else {
  //         return res;
  //       }
  //     })
  //     .catch((error) => console.log(error));
  // };




}
