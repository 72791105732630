import React, { useState, useEffect } from 'react';
import Helmet from 'react-helmet';
import { withTranslation } from 'react-i18next';
import PageTitle from '../../components/common/pageTitle';
import Spinner from '../../shared/Spinner';
import { withRouter } from 'react-router-dom';
import companyName from '../../../store/mobxStore/companyName';
import { useParams, useHistory } from 'react-router-dom';
import PaymentView from '../../../service/payment_view/PaymentView';
import { useLocation } from 'react-router-dom/cjs/react-router-dom';


function Seethepayment(props) {
    const params = useParams();
    const location = useLocation();
    let service = new PaymentView();

    const [state, setState] = useState({
        enc_order_id: '',
        invoice_no: '',
        pending: true
    })

    useEffect(() => {
        const url = window.location.href;

        // Extract the query parameters
        const paramsNew = new URLSearchParams(new URL(url).search);

        // Get values for userName and userEmail
        const subscription_id = paramsNew.get("subscription_id");
        const ba_token = paramsNew.get("ba_token");
        const token = paramsNew.get("token");
        console.log("subscription_id::", subscription_id);
        console.log("ba_token::", ba_token);
        console.log("token::", token);
        if (subscription_id) {

            service.subscriptionPaymentStatusCheck({
                subscription_id,
                ba_token,
                token
            })
                .then(res => {

                    if (res.status) {
                        props.history.push({
                            pathname: `/repurchaseInvoice/${res.data.data.enc_order_id}`,
                            state: {
                                invoice_no: res.data.data.invoice_no,
                                pending: res.data.data.pending
                            }
                        })


                        // setState(prevState => ({
                        //     ...prevState,
                        //     enc_order_id: res.data.data.enc_order_id,
                        //     invoice_no: res.data.data.invoice_no,
                        //     pending: res.data.data.pending
                        // }));

                        // if (state.enc_order_id !== '') {
                        //     props.history.push({
                        //         pathname: `/repurchaseInvoice/${state.enc_order_id}`,
                        //         state: {
                        //             invoice_no: state.invoice_no,
                        //             pending: state.pending
                        //         }
                        //     })
                        // }

                        console.log("the data in see the payment is here ", res)
                        console.log("the data within data in see the payment is here ", res.data)


                    } else {
                        if (res.error.code === 1002) {
                            props.history.push('/logout');
                        } else if (res.error.code === 1057) {
                            props.history.push({
                                pathname: '/dashboard',
                                state: {
                                    error: true,
                                    message: 'permissionDenied'
                                }
                            })
                        }
                        else if (res.error.code === 1054) {
                            props.history.push({
                                pathname: `/payment_view_fail/${params.token}/${params.PayerID}`,
                            })
                        }
                    }
                })
        }
        else {
            service.paymentStatusCheck(params)
                .then(res => {

                    if (res.status) {
                        props.history.push({
                            pathname: `/repurchaseInvoice/${res.data.data.enc_order_id}`,
                            state: {
                                invoice_no: res.data.data.invoice_no,
                                pending: res.data.data.pending
                            }
                        })


                        // setState(prevState => ({
                        //     ...prevState,
                        //     enc_order_id: res.data.data.enc_order_id,
                        //     invoice_no: res.data.data.invoice_no,
                        //     pending: res.data.data.pending
                        // }));

                        // if (state.enc_order_id !== '') {
                        //     props.history.push({
                        //         pathname: `/repurchaseInvoice/${state.enc_order_id}`,
                        //         state: {
                        //             invoice_no: state.invoice_no,
                        //             pending: state.pending
                        //         }
                        //     })
                        // }

                        console.log("the data in see the payment is here ", res)
                        console.log("the data within data in see the payment is here ", res.data)


                    } else {
                        if (res.error.code === 1002) {
                            props.history.push('/logout');
                        } else if (res.error.code === 1057) {
                            props.history.push({
                                pathname: '/dashboard',
                                state: {
                                    error: true,
                                    message: 'permissionDenied'
                                }
                            })
                        }
                        else if (res.error.code === 1054) {
                            props.history.push({
                                pathname: `/payment_view_fail/${params.token}/${params.PayerID}`,
                            })
                        }
                    }
                })
        }
    }, [])


    console.log("the param values are ", params)

    return (
        <div className="h-500" style={{ "display": "flex", "justifyContent": "center", "alignItems": "center", "left": "0", "top": "-80px", "height": "calc(100vh)", "position": "fixed", "width": "calc(100%)" }}>
            <center><h3>Please wait while we process your payment</h3></center><br /><br /><br /><br />
            <Spinner />
        </div>


    );




}
export default withTranslation()(withRouter(Seethepayment));